:root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;
    --font-color: #fff;
    --background-color: #040405;
    --secondary-color: #daa520;
    --font-size: 18px;

    --white-color: #fff;
    --black-color: #000;

    --main-color: #daa520;
    --main-color-darken: #9d6e01;

    --max-width-layout-sm: 600px;
    --max-width-layout-md: 1440px;
}

@media (prefers-color-scheme: dark) {
    :root {
        --foreground-rgb: 255, 255, 255;
        --background-start-rgb: 0, 0, 0;
        --background-end-rgb: 0, 0, 0;
    }
}

/*******************/
// Theme Fonts
/********************/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/*******************/
// Theme Colors
/********************/
$primary: #2f2f2f;
$info: #3699ff;
$danger: #f64e60;
$success: #0bb7af;
$warning: #ee9d01;
$dark: #343a40;
$light: #fff;
$secondary: #35363b;

$theme-colors: (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    'light-primary': #d6e4f3,
    'light-success': #d5f3f2,
    'light-info': #d3edfa,
    'light-warning': #f8ecdc,
    'light-danger': #f8dddd,
);
// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #eef5f9 !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #757e85 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #1e2a35 !default;
$black: #000 !default;

/*******************/
// Theme Text Contrast
/********************/
$min-contrast-ratio: 2;

/*******************/
// Theme body bg & text color
/********************/
$body-bg: $gray-200 !default;
$body-color: $gray-900 !default;

/*******************/
// Theme common variable
/********************/
$border-radius: 0.3rem !default;
$box-shadow: 0 0.5rem 1rem rgba($black, 0.05);
$headings-font-weight: 600 !default;

$card-border-width: 0 !default;
$card-box-shadow: $box-shadow !default;
$grid-gutter-width: 1.85rem !default;

$input-bg: $white !default;
/*******************/
// Theme btn
/********************/
$btn-focus-width: 0px;

/*******************/
// Theme sidebar width
/********************/
$sidebarWidth: 260px;
$sidebarColor: #f3f4f6;
