.pinvest-line-layout {
    margin: 1em;
    .top-profile {
        margin-bottom: 1em;
        display: flex;
        align-items: center;
        justify-content: end;
        .text-emp-code {
            margin-right: 4px;
            font-size: 16px;
        }
    }
}
