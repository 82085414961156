.cookie-policy {
    border: 0.5px solid black;
    background-color: black;
    width: 100%;
    height: 100%;
    position: relative;
    background-image:
        linear-gradient(to bottom, rgba(9, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), ),
        url('../../src/assets/images/bg-mb.png');
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 100px;

    @media (min-width: 1024px) {
        background-image:
            linear-gradient(to bottom, rgba(9, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), ),
            url('../../src/assets/images/bg-policy.png');
        background-size: cover;
    }

    p {
        margin-bottom: 0;
    }

    .content-footer-wrapper {
        padding-left: 1rem;
        padding-right: 1rem;

        @media (min-width: 768px) {
            padding-left: 2rem;
            padding-right: 2rem;
        }

        @media (min-width: 1024px) {
            padding-left: 4rem;
            padding-right: 4rem;
        }

        @media (min-width: 1024px) {
            padding-left: 8rem;
            padding-right: 8rem;
        }

        .header-title {
            background-color: #DBDBDB;
            border-radius: 10px;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;

            h1 {
                font-size: 24px;
                font-weight: 400;
                margin-bottom: 0;

                @media (min-width: 1024px) {
                    font-size: 35px;
                }
            }

            @media (min-width: 1440px) {
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }

        .main-content-text {
            color: var(--font-color);
            font-size: 15px;
            font-weight: 300;
            margin-top: 15px;

            @media (min-width: 1440px) {
                font-size: 18px;
                font-weight: 200;
                margin-top: 3rem;
            }

            .text {
                margin-bottom: 15px;

                @media (min-width: 1440px) {
                    margin-bottom: 2rem;
                }
            }

            .text-head {
                margin-bottom: 15px;

                @media (min-width: 1440px) {
                    margin-bottom: 25px;
                }
            }
        }
    }
}