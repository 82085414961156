.pinvestment-booking-page {
    .container {
        width: 100%;
        margin: 0 auto;
        max-width: 800px;
    }
    .main-content {
        margin: 1em 0;
    }
    .header-title {
        text-align: center;
    }
}

.pinvestment-confirm-page {
    .container {
        width: 100%;
        margin: 0 auto;
        max-width: 800px;
    }
    .main-content {
        margin: 1em 0;
    }
    .header-title {
        text-align: center;
    }
    .icon-wrapper {
        text-align: center;
        margin-bottom: 10px;
        .MuiIcon-fontSizeLarge {
            font-size: 10em;
        }
    }
}
