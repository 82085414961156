.invoices-page {
    .row-top {
        margin-bottom: 1rem;
    }
    .filter-service-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .form-item {
            margin-right: 5px;
            &:last-child {
                margin: 0;
            }
        }
    }
}
