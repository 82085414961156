.pageWrapper {
    min-height: 100vh;
}
.contentArea {
    flex-grow: 1;
    background-color: #fff;
}
.wrapper {
    // max-width: 1300px;
    margin: 0 auto;
}
.card {
    margin-bottom: 30px;
    box-shadow: $box-shadow;
}
.dropdown-menu {
    box-shadow: $box-shadow;
}
.cursor-pointer {
    cursor: pointer;
}
.circle-box {
    width: 100%;
    height: 100%;
    max-width: 40px;
    max-height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 100%;
    &.lg-box {
        max-width: 60px;
        max-height: 60px;
        font-size: 21px;
        line-height: 60px;
    }
    &.md-box {
        max-width: 50px;
        max-height: 50px;
        font-size: 18px;
        line-height: 50px;
    }
}
.button-group .btn {
    margin: 3px;
}
.btn-white {
    border: 1px solid $border-color;
}
.simplebar-scrollbar:before {
    background: #a0a0a0;
}
.apexcharts-toolbar {
    z-index: 0 !important;
}
