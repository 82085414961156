.register-page {
    background-color: var(--background-color);
    color: var(--font-color);
    padding-top: 9em;
    padding-bottom: 9em;
    padding-left: 1em;
    padding-right: 1em;
    .register-header {
        min-height: calc(100vh - 80vh);
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        .text-header {
            color: var(--main-color-darken);
            font-weight: 500;
            font-size: 14px;
            margin-top: 0;
            text-align: center;
        }
        .text-second {
            color: var(--white-color);
            font-weight: 500;
            font-size: 24px;
            margin-top: 0;
            margin-bottom: 0;
            text-align: center;
        }
    }
    .register-form {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        .tab-wrapper {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            background-color: #fff;
            display: flex;
            justify-content: space-between;
            padding: 0.5em 1em;
            .tab {
                width: 100%;
                border-bottom: 2px solid #ccc;
                text-align: center;
                &.active {
                    border-bottom: 2px solid #9d6e01;
                    button {
                        color: #9d6e01;
                    }
                }
                button {
                    color: var(--black-color);
                    border: 0;
                    font-size: 14px;
                    font-weight: 300;
                    background-color: transparent;
                }
            }
        }
        .react-datepicker-wrapper {
            width: 100%;
        }
        .react-datepicker__input-container {
            display: flex;
            align-items: center;
            svg {
                position: absolute;
                right: 0;
                margin-right: 1em;
            }
            input {
                outline: none;
                padding: 0.4em 0.8em;
            }
        }

        // custom style input phone
        .react-tel-input .form-control {
            width: 100%;
            border: 1px solid #daa520;
            outline: none;
            box-shadow: none;
            border-radius: 8px;
        }
        .react-tel-input .flag-dropdown {
            border: 1px solid #daa520;
        }
    }
}
