.breadcrumbs-content {
    background-color: white;
    padding: 1rem;

    a {
        color: #daa520;
    }
}

.car-detail-page {
    padding: 1rem;
    background-color: white;

    .car-detail-content {
        .car-detail-gallery {
            .preview-image {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                position: relative;

                .swiper {
                    width: 100%;
                    height: 100%;
                    max-width: 550px;
                    max-height: 370px;

                    .swiper-button-next {
                        background-color: #9d6e01;
                        color: white;
                        border-radius: 50%;
                        width: 30px;
                        height: 30px;

                        &::after {
                            font-size: 10px;
                        }
                    }

                    .swiper-button-prev {
                        background-color: #9d6e01;
                        color: white;
                        border-radius: 50%;
                        width: 30px;
                        height: 30px;

                        &::after {
                            font-size: 10px;
                        }
                    }

                    .swiper-pagination {
                        .swiper-pagination-bullet {
                            background-color: #daa520;
                        }

                        .swiper-pagination-bullet-active {
                            background-color: white;
                        }

                        @media (min-width: 768px) {
                            display: none;
                        }
                    }
                }

                .swiper-slide {
                    text-align: center;
                    font-size: 18px;
                    background: #fff;

                    /* Center slide text vertically */
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .swiper-slide img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .boxLeftArrow {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 60%;
                    margin: 20px;

                    @media (max-width: 768px) {
                        margin: 5px;
                        position: absolute;
                        z-index: 2;
                        left: 0;
                    }

                    &:hover {
                        opacity: 100%;
                    }

                    .arrowSlide {
                        width: 30px;
                        height: 30px;
                        background-color: #9d6e01;
                        border-radius: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: none;
                    }
                }

                .boxRightArrow {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 60%;
                    margin: 20px;

                    @media (max-width: 768px) {
                        margin: 5px;
                        position: absolute;
                        z-index: 2;
                        right: 0;
                    }

                    &:hover {
                        opacity: 100%;
                    }

                    .arrowSlide {
                        width: 30px;
                        height: 30px;
                        background-color: #9d6e01;
                        border-radius: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: none;
                    }
                }

                .image-show {
                    z-index: 1;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .bullet-image {
                    position: absolute;
                    z-index: 2;
                    display: flex;
                    flex-direction: row;
                    bottom: 10px;

                    @media (min-width: 768px) {
                        display: none;
                    }

                    .bullet-active {
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: #daa520;
                        margin-right: 5px;
                        border: 2px solid white;
                    }

                    .bullet {
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: #daa520;
                        margin-right: 5px;
                    }
                }
            }

            .image-options {
                display: none;

                padding-top: 1rem;

                @media (min-width: 768px) {
                    display: block;
                }

                @media (min-width: 1024px) {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }

                .image-select {
                    overflow: auto;
                    white-space: nowrap;
                    padding: 10px;
                    display: flex;
                    flex-direction: row;
                    scrollbar-width: none;
                    scroll-behavior: auto;
                    scroll-snap-type: x mandatory;
                    align-items: center;

                    @media (min-width: 768px) {
                        justify-content: center;
                    }

                    @media (min-width: 1024px) {
                        justify-content: center;
                    }

                    &::after {
                        content: '';
                        display: table;
                        clear: both;
                    }

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    .active-image {
                        margin-right: 10px;
                        cursor: pointer;

                        img {
                            width: 100%;
                            height: auto;
                        }
                    }

                    .active-border-image {
                        margin-right: 10px;
                        border: 3px solid #daa520;
                        cursor: pointer;

                        @media (min-width: 1024px) {
                            border: 3px solid #daa520;
                        }

                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }

            .price-car {
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-top: 1em;
                .radio {
                    padding: 0.13em 1.2em;
                    border-radius: 6px;
                    max-width: 200px;
                    margin-right: 20px;
                    margin-left: 20px;
                    text-align: center;

                    @media (min-width: 768px) {
                        padding: 0.13em 0.3em;
                    }

                    [type='radio']:checked,
                    [type='radio']:not(:checked) {
                        display: none;
                    }

                    [type='radio']:checked + label,
                    [type='radio']:not(:checked) + label {
                        position: relative;
                        padding-left: 28px;
                        cursor: pointer;
                        line-height: 20px;
                        display: inline-block;
                        color: #daa520;
                        font-size: 14px;

                        @media (min-width: 1024px) {
                            font-size: 19px;
                            line-height: 23px;
                            padding-left: 35px;
                        }
                    }

                    [type='radio']:checked + label:before,
                    [type='radio']:not(:checked) + label:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 18px;
                        height: 18px;
                        border: 1px solid var(--main-color);
                        border-radius: 100%;
                        background: transparent;

                        @media (min-width: 1024px) {
                            width: 23px;
                            height: 23px;
                        }
                    }

                    [type='radio']:checked + label:after,
                    [type='radio']:not(:checked) + label:after {
                        content: '';
                        width: 12px;
                        height: 12px;
                        background: #daa520;
                        position: absolute;
                        top: 3px;
                        left: 3px;
                        border-radius: 100%;
                        -webkit-transition: all 0.5s ease;
                        transition: all 0.2s ease;

                        @media (min-width: 1024px) {
                            width: 17px;
                            height: 17px;
                        }
                    }

                    [type='radio']:not(:checked) + label:after {
                        opacity: 0;
                        -webkit-transform: scale(0);
                        transform: scale(0);
                    }

                    [type='radio']:checked + label:after {
                        opacity: 1;
                        -webkit-transform: scale(1);
                        transform: scale(1);
                    }
                }
            }

            .btn-book-car {
                max-width: 200px;
                margin: 0 auto;

                button {
                    padding: 0.3em 0.2em;
                }
            }

            .car-detail {
                display: flex;
                flex-direction: column;
                align-items: space-around;
                margin-top: 2rem;
                border-bottom: 2px solid #daa520;
                padding-left: 1rem;
                padding-right: 1rem;

                @media (min-width: 768px) {
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-end;
                }

                @media (min-width: 1440px) {
                    max-width: 80rem;
                    margin: 0 auto;
                }

                p {
                    margin-bottom: 0;
                    color: #b4904f;
                    font-size: 22px;

                    @media (min-width: 1440px) {
                        font-size: 26px;
                        font-weight: 600;
                    }
                }

                .iconOptions {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;

                    .icon {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .imgIcon {
                            width: 25px;
                            height: 25px;

                            @media (min-width: 1440px) {
                                width: 32px;
                                height: 32px;
                            }
                        }

                        &:first-child {
                            border-right: none;
                            border-left: none;
                        }

                        &:last-child {
                            border-right: none;
                            border-left: none;
                        }

                        .text {
                            color: var(--secondary-color);
                            font-size: 14px;
                            font-weight: 500;
                        }
                    }

                    .divider {
                        height: 40px;
                        width: 2px;
                        background-color: #ffc978;
                        margin-left: 10px;
                        margin-right: 10px;
                    }
                }
            }

            .list-detail {
                padding-top: 1rem;

                @media (min-width: 1440px) {
                    max-width: 77rem;
                    margin: 0 auto;
                }

                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    color: #8c7d7a;
                }

                .help-feedback {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                    cursor: pointer;

                    &:hover {
                        text-decoration: none;
                    }

                    .helpIcon {
                        width: 20px;
                        height: 20px;
                    }

                    .text {
                        margin-left: 5px;
                        color: #daa520;
                        font-size: 10px;
                        font-weight: 300;

                        @media (min-width: 1440px) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
