.contact-page {
    border: 0.5px solid black;
    background-color: black;
    width: 100%;
    height: 100%;
    position: relative;
    background-image:
        linear-gradient(to bottom, rgba(9, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), ),
        url('../../src/assets/images/bg-mb.png');
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 100px;
    padding-bottom: 5rem;

    @media (min-width: 1024px) {
        background-image:
            linear-gradient(to bottom, rgba(9, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), ),
            url('../../src/assets/images/bg-lg.png');
        padding-bottom: 8rem;
    }


    .contact-title {
        margin-bottom: 1rem;

        .contact-route {
            text-align: center;

            .btn-back {
                color: #DAA520;
                border-bottom: 1px solid #DAA520;
                text-decoration: none;
                cursor: pointer;
            }
        }

        .contact-title-text {
            margin-top: 2rem;
            color: white;
            font-size: 48px;
            font-weight: 500;
            text-align: center;
        }
    }

    .contact-content {
        background-color: var(--font-color);
        padding: 1rem 1rem 5rem 1rem;
        border-radius: 10px;
        margin-left: 1rem;
        margin-right: 1rem;

        @media (min-width: 1024px) {
            padding: 1rem 4rem 5rem 4rem;
            margin-left: 3rem;
            margin-right: 3rem;
        }

        @media (min-width: 1440px) {
            padding: 1rem 12rem 5rem 12rem;
        }

        .conteact-content-wrapper {
            @media (min-width: 768px) {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
            }

            .contact-section-address {
                width: 100%;

                .address-header {
                    font-size: 24px;
                    font-weight: 300;
                    color: #DAA520;
                }

                .address-detail {
                    margin-top: 1.5rem;
                    font-size: 16px;
                    font-weight: 200;

                    p {
                        margin-bottom: 0;
                    }
                }
            }

            .contact-section-contact {
                margin-top: 1.5rem;
                width: 100%;

                @media (min-width: 768px) {
                    margin-top: 0;
                }

                .contact-header {
                    font-size: 24px;
                    font-weight: 300;
                    color: #DAA520;
                    margin-bottom: 1.5rem;
                }

                .contact-list {
                    display: grid;
                    grid-template-columns: 50% 50%;

                    .contact-list-item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-bottom: 1rem;
                        cursor: pointer;

                        &:hover {
                            opacity: 0.7;
                        }

                        .img-item {
                            width: 23px;
                            height: 23px;
                        }

                        .contact-list-title {
                            margin-left: 15px;
                            font-size: 14px;
                            font-weight: 400;

                            .title {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }

        .contact-section-contact-form {
            .contact-form-header {
                font-size: 24px;
                font-weight: 300;
                color: #DAA520;
                margin-bottom: 1.5rem;
                margin-top: 1.5rem;
            }
        }
    }

    .contact-section-map {
        padding: 6rem 1rem 6rem 1rem;

        @media (min-width: 1024px) {
            padding: 3rem 4rem 3rem 4rem;
        }

        @media (min-width: 1440px) {
            padding: 3rem 12rem 3rem 12rem;
        }

        .google-map-content {
            width: 100%;
            height: 530px;
            border-radius: 10px;
        }
    }

    // custom style input phone
    .react-tel-input .form-control {
        width: 100%;
        border: 1px solid #daa520;
        outline: none;
        box-shadow: none;
        border-radius: 8px;
    }

    .react-tel-input .flag-dropdown {
        border: 1px solid #daa520;
    }
}