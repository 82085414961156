@import 'variables';
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons.scss';
@import 'reset';
@import 'react-toastify/dist/ReactToastify.css';
@import 'react-datepicker/dist/react-datepicker.css';
@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';
// layout
@import './layout/admin/admin';
@import './layout/client';
@import './layout/pinvestment';
// page
@import './page/contact';
@import './page/termsOfService.scss';
@import './page/ourServices';
@import './page/profile';
@import './page/privacy-policy';
@import './page/cookie-policy';
@import './page/payment-methods';
@import './page/cancellation';
@import './page/terms-and-condition';
@import './page/car-detail';
// videojs
@import 'video.js/dist/video-js.css';
@import './vjs-skin.scss';

@media (max-width: 768px) {
    .fc {
        .fc-header-toolbar {
            .fc-toolbar-chunk {
                .fc-toolbar-title {
                    font-size: 0.9em !important;
                    text-align: center;
                }

                button {
                    font-size: 0.7em !important;
                }
            }
        }

        .fc-view {
            font-size: 0.8em;
        }
    }
}

.dropdown-menu {
    --bs-dropdown-bg: #fff;
}

.modal {
    --bs-body-bg: #fff;
}

.table {
    --bs-table-color: var(--bs-body-color);
    --bs-table-bg: #fff;
}

.loading-icon-payment {
    margin-left: 5px;
    color: var(--white-color);
    font-size: 1rem;
    animation: animate 2s infinite;
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(720deg);
    }
}