.payment-methods-content {
    border: 0.5px solid black;
    background-color: black;
    width: 100%;
    height: 100%;
    position: relative;
    background-image:
        linear-gradient(to bottom, rgba(9, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), ),
        url('../../src/assets/images/bg-mb.png');
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 100px;

    @media (min-width: 1024px) {
        background-image:
            linear-gradient(to bottom, rgba(9, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), ),
            url('../../src/assets/images/bg-policy.png');
        background-size: cover;
    }

    p {
        margin-bottom: 0;
    }

    .content-footer-wrapper {
        padding-left: 1rem;
        padding-right: 1rem;

        @media (min-width: 768px) {
            padding-left: 2rem;
            padding-right: 2rem;
        }

        @media (min-width: 1024px) {
            padding-left: 4rem;
            padding-right: 4rem;
        }

        @media (min-width: 1024px) {
            padding-left: 8rem;
            padding-right: 8rem;
        }

        .header-title {
            background-color: #DBDBDB;
            border-radius: 10px;
            text-align: center;

            h1 {
                font-size: 24px;
                font-weight: 400;
                margin-bottom: 0;

                @media (min-width: 1024px) {
                    font-size: 35px;
                }
            }

            @media (min-width: 1440px) {
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }

        .main-content-text {
            color: var(--font-color);
            font-size: 15px;
            font-weight: 300;
            margin-top: 15px;

            @media (min-width: 1440px) {
                font-size: 18px;
                font-weight: 200;
                margin-top: 3rem;
            }

            .text {
                margin-bottom: 15px;
            }

            .text-top {
                margin-top: 35px;
            }

            .text-bottom {
                margin-bottom: 35px;
            }

            .icon {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-right: 10px;
                padding-left: 10px;
                margin-top: 15px;

                .cc-img {
                    height: 36px;

                    @media (min-width: 768px) {
                        height: 100%;
                    }
                }

            }

            .icon-pp {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                padding-right: 10px;
                padding-left: 10px;

                .prompt-pay-img {
                    height: 58px;

                    @media (min-width: 768px) {
                        height: 100%;
                    }
                }
            }

            .icon-bank {
                margin-top: 10px;

                .bank-img {
                    display: none;
                    margin: 0 auto;

                    @media (min-width: 768px) {
                        display: block;
                    }
                }

                .bank-img-mb {
                    margin: 0 auto;

                    @media (min-width: 768px) {
                        display: none;
                    }
                }
            }
        }
    }
}