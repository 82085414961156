.profile-page {
    background-color: black;
    width: 100%;
    height: 100%;
    position: relative;
    background-image:
        linear-gradient(to bottom, rgba(9, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), ),
        url('../../src/assets/images/contactUs/bg-contact-mb.png');
    background-repeat: no-repeat;
    background-size: contain;

    @media (min-width: 1024px) {
        background-image:
            linear-gradient(to bottom, rgba(9, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), ),
            url('../../src/assets/images/ourServices/bg-our-services-lg.png');
    }

    .image-bg {
        .image-bg-mb {
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;

            @media (min-width: 1024px) {
                display: none;
            }
        }

        .image-bg-lg {
            display: none;
            width: 100%;

            @media (min-width: 1024px) {
                display: block;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                position: absolute;
            }

            @media (min-width: 1440px) {
                display: block;
                left: 0;
                right: 0;
                bottom: 0;
                position: absolute;
            }
        }
    }

    .title-page {
        padding-top: 6rem;
        position: absolute;
        z-index: 3;
        font-size: 20px;
        font-weight: 300;
        width: 100%;
        text-align: center;
        color: white;

        @media (min-width: 1440px) {
            font-size: 48px;
            padding-top: 4.5rem;
        }
    }

    .frame-profile-page {
        padding: 10rem 0rem 0rem 0rem;
        position: relative;
        z-index: 2;

        .content-profile-page {
            padding: 2rem 1rem 2rem 1rem;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            background-color: var(--font-color);

            @media (min-width: 768px) {
                padding: 2rem 8rem 2rem 8rem;
            }

            @media (min-width: 1024px) {
                padding: 2rem 14rem 2rem 14rem;
            }

            @media (min-width: 1440px) {
                padding: 2rem 36rem 2rem 36rem;
            }

            .header {
                text-align: center;
                font-size: 28px;
                font-weight: 500;
                color: #37302E;
            }

            .avatar-image {
                text-align: center;
            }

            .point-total {
                text-align: center;
                font-size: 18px;
                font-weight: 500;
                color: #9D6E01;
                padding-top: 0.5rem;
            }

            .redeem {
                padding-left: 5rem;
                padding-right: 5rem;
                padding-bottom: 1rem;

                @media (min-width: 1024px) {
                    padding-left: 10rem;
                    padding-right: 10rem;
                }

                @media (min-width: 1440px) {
                    padding-left: 12rem;
                    padding-right: 12rem;
                }

                .btn-redeem {
                    margin: 0 auto;
                    margin-top: 1rem;
                    padding: 0.34em 0.7em;
                    width: 100%;
                    display: block;
                    background: linear-gradient(45deg, #c5a052, #f3e695, #b9974b);
                    border-radius: 8px;
                    border-color: transparent;
                    color: var(--black-color);
                    text-decoration: none;
                    font-size: 16px;
                    font-weight: 700;
                }
            }

            .button-action {
                border-bottom: 1px solid #D8D8D8;
                padding-bottom: 20px;

                .button-action-wrapper {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;

                    .edit-button {
                        width: 100%;
                        padding-left: 6rem;
                        padding-right: 6rem;

                        .btn {
                            width: 100%;
                            color: #E0D4CA;
                            background-color: #635855;
                            opacity: 0.3;

                            &:hover {
                                opacity: 0.5;
                            }
                        }
                    }

                    .submit-button {
                        width: 100%;
                        padding-left: 2rem;
                        padding-right: 2rem;

                        .btn {
                            width: 100%;
                            color: #E0D4CA;
                            background-color: #635855;
                            opacity: 0.3;

                            &:hover {
                                opacity: 0.5;
                            }
                        }
                    }
                }
            }

            .content-booking-history {
                margin-top: 20px;

                .booking-history-title {
                    text-align: center;
                }
            }

            .booking-list {
                .booking-container {
                    display: grid;
                    grid-template-columns: 20% 60% 20%;
                    font-size: 10px;
                    border: 1px solid #DAA520;
                    border-radius: 8px;
                    margin-top: 20px;
                    padding: 10px;

                    @media (min-width: 1440px) {
                        font-size: 16px;
                        color: #5B5B5B;
                    }

                    .car-detail {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .img-car {
                            .car-icon {
                                margin: 0 auto;
                                width: 25px;
                                height: 25px;

                                @media (min-width: 1024px) {
                                    display: none;
                                }
                            }

                            .car-icon-lg {
                                display: none;
                                margin: 0 auto;
                                width: 85px;
                                height: 85px;

                                @media (min-width: 1024px) {
                                    display: block;
                                }
                            }
                        }

                        .name-car {
                            text-align: center;
                        }
                    }

                    .book-detail {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .book-detail-wrap {
                            .content {
                                display: flex;
                                flex-direction: row;
                                align-items: center;

                                @media (min-width: 1024px) {
                                    margin-bottom: 10px;

                                    &:last-child {
                                        margin-bottom: 0px;
                                    }
                                }

                                .icon-detail {
                                    width: 10px;
                                    height: 10px;
                                    margin-right: 5px;

                                    @media (min-width: 1024px) {
                                        display: none;
                                    }
                                }

                                .icon-detail-lg {
                                    display: none;
                                    width: 27px;
                                    height: 27px;
                                    margin-right: 10px;

                                    @media (min-width: 1024px) {
                                        display: block;
                                    }
                                }

                                .text {
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                        }
                    }

                    .status-detail {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .icon-status {
                            width: 25px;
                            height: 25px;
                            margin: 0 auto;

                            @media (min-width: 1024px) {
                                display: none;
                            }
                        }

                        .icon-status-lg {
                            display: none;
                            margin: 0 auto;
                            width: 85px;
                            height: 85px;

                            @media (min-width: 1024px) {
                                display: block;
                            }
                        }

                        .pending {
                            text-align: center;
                            color: orange;
                        }

                        .success {
                            text-align: center;
                            color: green;
                        }

                        .failed {
                            text-align: center;
                            color: red;
                        }
                    }
                }
            }
        }
    }


}