.termsOfService-page {
    border: 0.5px solid black;
    background-color: black;
    width: 100%;
    height: 100%;
    position: relative;
    background-image:
        linear-gradient(to bottom, rgba(9, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), ),
        url('../../src/assets/images/bg-mb.png');
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 100px;

    @media (min-width: 1024px) {
        background-image:
            linear-gradient(to bottom, rgba(9, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), ),
            url('../../src/assets/images/bg-policy.png');
    }

    .bg-terms-page {

        .contact-title {
            // position: absolute;
            // top: 100px;
            // left: 0px;
            // text-align: center;
            // right: 0px;

            // @media (min-width: 425px) {
            //     top: 140px;
            // }

            // @media (min-width: 768px) {
            //     top: 250px;
            // }

            // @media (min-width: 1024px) {
            //     top: 90px;
            // }

            .contact-route {
                text-align: center;

                .btn-back {
                    color: #DAA520;
                    border-bottom: 1px solid #DAA520;
                    text-decoration: none;
                    cursor: pointer;
                }
            }

            .contact-title-text {
                text-align: center;
                margin-top: 2rem;
                color: white;
                font-size: 48px;
                font-weight: 500;
            }
        }

        .terms-content {
            padding-left: 1rem;
            padding-right: 1rem;

            @media (min-width: 1024px) {
                padding-left: 4rem;
                padding-right: 4rem;
                padding-bottom: 5rem;
            }

            .terms-content-list {
                border-top: 0.5px solid;
                border-image: linear-gradient(to top, #E0D4CA, #635855);
                border-image-slice: 1;
                padding-top: 1rem;
                margin-top: 1.5rem;

                @media (min-width: 1024px) {
                    display: grid;
                    grid-template-columns: 20% 40% 40%;

                    &:last-child {
                        border-bottom: 0.5px solid;
                        border-image: linear-gradient(to top, #E0D4CA, #635855);
                        border-image-slice: 1;
                    }
                }

                .list-number {
                    color: #DAA520;
                    background-color: black;
                    border: 1px solid #DAA520;
                    width: 50px;
                    height: 30px;
                    border-radius: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    p {
                        margin-bottom: 0;
                    }
                }

                .list-head-title {
                    color: white;
                    font-size: 24px;
                    font-weight: 400;
                    margin-top: 1rem;
                    margin-bottom: 1rem;

                    @media (min-width: 1024px) {
                        margin-top: 0rem;
                        margin-bottom: 0rem;
                    }
                }

                .list-three-title {
                    .list-desc {
                        font-size: 16px;
                        color: white;
                        font-weight: 200;
                    }

                    .list-desc-second {
                        margin-top: 1.5rem;
                        margin-bottom: 1.5rem;
                        font-size: 16px;
                        color: white;
                        font-weight: 200;

                        .text-line-id {
                            color: #DAA520;
                        }

                        .text-email {
                            color: #DAA520;
                            text-decoration: underline;
                        }
                    }

                    .list-desc-third {
                        font-size: 16px;
                        color: white;
                        font-weight: 200;
                    }

                    .list-table-header {
                        color: white;
                        font-size: 20px;
                        font-weight: 400;
                        margin-top: 1.5rem;
                        margin-bottom: 1.5rem;
                    }

                    .list-table-data {
                        display: grid;

                        .table-list {
                            background-color: black;

                            tr {
                                td {
                                    border: 0.5px solid;
                                    border-image: linear-gradient(to top, #E0D4CA, #635855);
                                    border-image-slice: 1;
                                    padding-left: 2rem;

                                    .text {
                                        font-size: 12px;
                                        color: white;
                                        font-weight: 100;
                                    }
                                }
                            }
                        }
                    }

                    .list-last-desc {
                        margin-top: 1.5rem;
                        padding-bottom: 1.5rem;
                        font-size: 16px;
                        color: white;
                        font-weight: 200;
                    }
                }
            }
        }
    }
}