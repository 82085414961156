// for override videoJS style
.video-js .vjs-big-play-button {
    width: 3em;
    height: 1.8em;
    line-height: 1.8em;
    border-radius: 12px;
    border: none;
    background-color: #daa520;
    top: 50%;
    left: 50%;
    margin-top: -44px;
    margin-left: -44px;
    .vjs-icon-placeholder::before {
        font-size: 1.2em;
    }
}
.video-js .vjs-time-control {
    display: block;
}
.video-js .vjs-remaining-time {
    display: none;
}

.video-js .vjs-time-control {
    text-align: center;
}

.video-js .vjs-picture-in-picture-control {
    margin-left: auto;
}

.video-js .vjs-play-progress {
    background-color: #daa520;
}

.video-js .vjs-time-control {
    line-height: 2.8em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-size: 1.2em;
}

.video-js .vjs-icon-placeholder::before {
    font-size: 2em;
}

.video-js .vjs-audio-button {
    margin-left: auto;
}

.video-js .vjs-progress-control {
    order: 1;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    .vjs-progress-holder {
        height: 4px;
        margin: 0 4px;
        .vjs-play-progress:before {
            font-size: 1em;
        }
    }
}

.video-js .vjs-control-bar {
    height: 5em;
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.73) 100%
    );
    padding: 1em 10px 1em 10px;
}

// for custom component
.video-js {
    .vjs-title-bar {
        position: absolute;
        line-height: 1.4em;
        top: 0px;
        left: 0px;
        width: 100%;
        display: block;
        background: linear-gradient(
            to bottom,
            rgb(0 0 0 / 82%) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        color: #fff;
        padding: 0.5em 0.5em 2em 0.5em;
        font-weight: 600;
        font-size: 1.5em;
        pointer-events: none;
        @media (min-width: 576px) {
            font-size: 1.3vw;
        }
        @media (min-width: 768px) {
            padding: 0.75em 0.75em 3em 0.75em;
        }
        @media (min-width: 992px) {
            padding: 1em 1em 4em 1em;
        }
    }
    &.vjs-has-started .vjs-title-bar,
    &.vjs-error .vjs-title-bar {
        opacity: 0;
    }
    &.vjs-playing {
        .vjs-title-bar {
            visibility: visible;
            opacity: 0;
            transition: visibility 1s, opacity 1s;
        }
    }
    &.vjs-paused {
        .vjs-title-bar {
            opacity: 1;
        }
    }
    &.vjs-user-active {
        .vjs-title-bar {
            opacity: 1;
        }
    }
}
