.booking-page {
    border: 0.5px solid black;
    background-color: black;
    width: 100%;
    height: 100%;
    position: relative;
    background-image:
        linear-gradient(to bottom, rgba(9, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), ),
        url('../../src/assets/images/bg-mb.png');
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 100px;
    padding-bottom: 5rem;

    @media (min-width: 1024px) {
        background-image:
            linear-gradient(to bottom, rgba(9, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), ),
            url('../../src/assets/images/bg-lg.png');
        padding-bottom: 8rem;
    }

    .booking-header {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 2rem;

        @media (min-width: 768px) {
            padding-bottom: 4rem;
        }

        .text-header {
            color: var(--main-color-darken);
            font-weight: 300;
            font-size: 14px;
            margin: 0;
            text-align: center;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }

            span {
                margin-left: 5px;
            }
        }

        .text-second {
            color: var(--white-color);
            font-weight: 300;
            font-size: 24px;
            margin-top: 0;
            margin-bottom: 0;
            text-align: center;

            @media (min-width: 768px) {
                font-size: 48px;
            }
        }
    }

    // .new-booking-header {
    //     border: 0.5px solid black;
    //     background-color: black;
    //     width: 100%;
    //     height: 100%;
    //     position: relative;
    //     background-image:
    //         linear-gradient(to bottom, rgba(9, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), ),
    //         url('../../src/assets/images/bg-mb.png');
    //     background-repeat: no-repeat;
    //     background-size: contain;
    //     padding-top: 100px;

    //     @media (min-width: 1024px) {
    //         background-image:
    //             linear-gradient(to bottom, rgba(9, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), ),
    //             url('../../src/assets/images/bg-lg.png');
    //     }

    //     display: flex;
    //     flex-flow: column;
    //     align-items: center;
    //     justify-content: center;

    //     .text-header {
    //         color: var(--main-color-darken);
    //         font-weight: 300;
    //         font-size: 14px;
    //         margin: 0;
    //         text-align: center;
    //         cursor: pointer;

    //         &:hover {
    //             text-decoration: underline;
    //         }

    //         span {
    //             margin-left: 5px;
    //         }
    //     }

    //     .text-second {
    //         color: var(--white-color);
    //         font-weight: 300;
    //         font-size: 24px;
    //         margin-top: 0;
    //         margin-bottom: 0;
    //         text-align: center;

    //         @media (min-width: 768px) {
    //             font-size: 48px;
    //         }
    //     }
    // }

    .booking-form {
        min-height: calc(100vh - 26vh);

        @media (min-width: 768px) {
            min-height: calc(100vh - 30vh);
        }

        background-color: var(--white-color);

        .invalid-booking {
            max-width: 400px;
            margin: 0 auto;
            padding: 1em 1em;
        }

        .loading-wrapper {
            text-align: center;
            padding: 2em;
        }

        .loading-icon {
            color: teal;
            font-size: 2rem;
            animation: animate 2s infinite;
        }

        .loading-icon-payment {
            margin-left: 5px;
            color: var(--white-color);
            font-size: 1rem;
            animation: animate 2s infinite;
        }

        @keyframes animate {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(720deg);
            }
        }
    }

    .booking-form-two {
        min-height: calc(100vh - 26vh);

        @media (min-width: 768px) {
            min-height: calc(100vh - 30vh);
        }

        @media (min-width: 1024px) {
            max-width: 840px;
            margin: 0 auto;
        }

        background-color: var(--white-color);
        margin-left: 2rem;
        margin-right: 2rem;
        padding: 5px;
        border-radius: 10px;

        .invalid-booking {
            max-width: 400px;
            margin: 0 auto;
            padding: 1em 1em;
        }

        .loading-wrapper {
            text-align: center;
            padding: 2em;
        }

        .loading-icon {
            color: teal;
            font-size: 2rem;
            animation: animate 2s infinite;
        }

        .loading-icon-payment {
            margin-left: 5px;
            color: var(--white-color);
            font-size: 1rem;
            animation: animate 2s infinite;
        }

        @keyframes animate {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(720deg);
            }
        }
    }

    .react-datepicker-wrapper {
        width: 100%;
    }

    .react-datepicker__input-container {
        display: flex;
        align-items: center;

        svg {
            position: absolute;
            right: 0;
            margin-right: 1em;
        }

        input {
            outline: none;
            padding: 0.4em 0.8em;
        }
    }

    // custom style input phone
    .react-tel-input .form-control {
        width: 100%;
        border: 1px solid #daa520;
        outline: none;
        box-shadow: none;
        border-radius: 8px;
    }

    .react-tel-input .flag-dropdown {
        border: 1px solid #daa520;
    }
}

.booking-page-spacial {
    border: 0.5px solid black;
    background-color: black;
    width: 100%;
    height: 100%;
    position: relative;
    background-image:
        linear-gradient(to bottom, rgba(9, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), ),
        url('../../src/assets/images/background/booking-banner.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top 80px right 0px;
    padding-top: 100px;
    padding-bottom: 5rem;
    padding-left: 1rem;
    padding-right: 1rem;

    @media (min-width: 768px) {
        background-position: top 55px right 0px;
    }


    @media (min-width: 1024px) {
        background-image:
            linear-gradient(to bottom, rgba(9, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), ),
            url('../../src/assets/images/background/booking-banner.png');
        padding-bottom: 8rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .booking-header {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 2rem;

        @media (min-width: 768px) {
            padding-bottom: 4rem;
        }

        .text-header {
            color: var(--main-color-darken);
            font-weight: 300;
            font-size: 14px;
            margin: 0;
            text-align: center;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }

            span {
                margin-left: 5px;
            }
        }

        .text-second {
            color: var(--white-color);
            font-weight: 300;
            font-size: 24px;
            margin-top: 0;
            margin-bottom: 0;
            text-align: center;

            @media (min-width: 768px) {
                font-size: 48px;
            }
        }
    }

    .booking-form {
        min-height: calc(100vh - 26vh);

        @media (min-width: 768px) {
            min-height: calc(100vh - 30vh);
        }

        background-color: var(--white-color);

        .invalid-booking {
            max-width: 400px;
            margin: 0 auto;
            padding: 1em 1em;
        }

        .loading-wrapper {
            text-align: center;
            padding: 2em;
        }

        .loading-icon {
            color: teal;
            font-size: 2rem;
            animation: animate 2s infinite;
        }

        .loading-icon-payment {
            margin-left: 5px;
            color: var(--white-color);
            font-size: 1rem;
            animation: animate 2s infinite;
        }

        @keyframes animate {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(720deg);
            }
        }
    }

    .booking-form-two {
        min-height: calc(100vh - 26vh);
        width: 100%;

        @media (min-width: 1024px) {
            min-height: calc(100vh - 30vh);
            margin-left: 2rem;
            margin-right: 2rem;
        }

        @media (min-width: 1024px) {
            margin: 0 auto;
        }

        background-color: var(--white-color);
        padding: 5px;
        border-radius: 10px;

        .invalid-booking {
            max-width: 400px;
            margin: 0 auto;
            padding: 1em 1em;
        }

        .loading-wrapper {
            text-align: center;
            padding: 2em;
        }

        .loading-icon {
            color: teal;
            font-size: 2rem;
            animation: animate 2s infinite;
        }

        .loading-icon-payment {
            margin-left: 5px;
            color: var(--white-color);
            font-size: 1rem;
            animation: animate 2s infinite;
        }

        @keyframes animate {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(720deg);
            }
        }
    }

    .react-datepicker-wrapper {
        width: 100%;
    }

    .react-datepicker__input-container {
        display: flex;
        align-items: center;

        svg {
            position: absolute;
            right: 0;
            margin-right: 1em;
        }

        input {
            outline: none;
            padding: 0.4em 0.8em;
        }
    }

    // custom style input phone
    .react-tel-input .form-control {
        width: 100%;
        border: 1px solid #daa520;
        outline: none;
        box-shadow: none;
        border-radius: 8px;
    }

    .react-tel-input .flag-dropdown {
        border: 1px solid #daa520;
    }
}