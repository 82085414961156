.ourServices-page {
    border: 0.5px solid black;
    background-color: black;
    width: 100%;
    height: 100%;
    position: relative;
    background-image:
        linear-gradient(to bottom, rgba(9, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), ),
        url('../../src/assets/images/bg-mb.png');
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 100px;

    @media (min-width: 1024px) {
        background-image:
            linear-gradient(to bottom, rgba(9, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), ),
            url('../../src/assets/images/bg-lg.png');
    }

    .bg-ourServices {

        .bg-title {

            .btn-overview {
                text-align: center;

                .btn-sub-overview {
                    border-bottom: 1px solid var(--secondary-color);
                    width: 220px;
                    margin: 0 auto;

                    .btn {
                        cursor: pointer;
                        display: inline-block;
                        color: var(--secondary-color);
                        margin-right: 5px;
                        text-decoration: none;
                    }
                }

                .header-content {
                    margin-top: 1.5rem;

                    .text-page {
                        color: var(--secondary-color);
                        font-weight: 300;
                        font-size: 14px;
                    }

                    .title-header {
                        padding-top: 1rem;
                        width: 100%;
                        max-width: 800px;
                        margin: 0 auto;

                        h2 {
                            font-size: 24px;
                            font-weight: 500;
                            color: var(--font-color);

                            @media (min-width: 1024px) {
                                font-size: 48px;
                            }
                        }
                    }
                }

                .main-content {
                    margin-top: 7rem;
                }
            }
        }
    }
}