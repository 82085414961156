html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Inter', sans-serif;
    font-family: 'Kanit', sans-serif;
    line-height: 1.6;
    font-size: var(--font-size);
    vertical-align: baseline;
}

* {
    box-sizing: border-box;
}

a {
    color: #0070f3;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

img {
    max-width: 100%;
    display: block;
}
