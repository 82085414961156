.admin-booking-job-tracking-page {
    .create-wrapper {
        margin-bottom: 1em;
        text-align: end;
    }

    .header-text {
        .header-text-light {
            font-weight: 200;
        }

        .header-text-driver-running {
            font-weight: 200;
            font-size: 16px;
            margin-left: 1em;
            color: #1c7dde;
        }

        .header-text-driver-not-running {
            font-weight: 200;
            font-size: 16px;
            margin-left: 1em;
            color: red;
        }
    }

    .row-wrapper {
        margin-bottom: 2em;
    }

    .job-item-row {
        display: flex;

        .no {
            width: 15%;
        }

        .issue {
            width: 30%;
        }

        .detail {
            width: 55%;
        }
    }

    .checked {
        color: green;
        font-size: 1.5rem;
    }

    .checked-yet {
        color: red;
        font-size: 1.5rem;
    }

    .row-detail-multiple {
        display: flex;
        align-items: center;

        .block {
            margin-right: 2em;

            &:last-child {
                margin-right: 0;
            }
        }

        .image {
            max-width: 300px;
            border-radius: 8px;
        }
    }

    .create-link-payment {
        font-size: 16px;
        color: #4291ff;
        text-decoration: underline;
        cursor: pointer;

        &.disable {
            background-color: rgb(220, 220, 220);
            cursor: not-allowed;
            color: #b7b7b7;
            text-decoration: none;
        }
    }

    .finished-calling-content {
        display: flex;
        flex-direction: row;
        align-items: center;

        .btn-finished-calling {
            background-color: #008d00;
            color: #fff;
            font-size: 16px;
            border: 1px solid #008d00;
            border-radius: 4px;
            cursor: pointer;
            padding: 8px 12px;

            &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }

            margin-right: 15px;
        }
    }
}